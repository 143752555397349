import { Options } from "vue-class-component";
import WebSocketService from "@/websocket/WebSocketService";
import MessageRequest from "@/websocket/MessageRequest";
import MessageResponse from "@/websocket/MessageResponse";
import { WebSocketMessageUrl } from "@/utils/axios";
import { v4 as uuidv4 } from "uuid";
import { useToast } from "vue-toastification";
import WorksTableItem from "@/entity/aftersale/ticket/WorksTableItem";
import WorksApi from "@/api/aftersale/ticket/works";
import UserApi from "@/api/system/user1";
import BusinessApi from "@/api/aftersale/ticket/business";
import InfluenceApi from "@/api/aftersale/ticket/influence";
import SelectLevel from "@/modules/select-level/select-level.vue";
import BaseFunction from "@/mixins/BaseFunction";
import UserAPI from "@/api/system/user1";

@Options({
    name: "app-work-view",
    components: {
        "app-select-level": SelectLevel,
    },
    props: {
        modelValue: Object,
        workStyle: {
            type: String,
            default: null,
        },
        //传workCode会从数据库更新工单信息，不传则使用modelValue
        workCode: {
            type: String,
            default: null,
        },
    },
    watch: {
        infoList: {
            deep: true,
            handler: function () {
                this.scrollToBottom();
            },
        },
        windowHeight: function () {
            this.scrollToBottom();
        },
    },
    emits: ["update:modelValue"],
})
export default class WorkView extends BaseFunction {
    private loading1 = false;
    private loading2 = false;
    private loading3 = false;
    private activeTab = "COMMON1";
    private workStyle = "";
    private workCode = "";
    private message = "";
    private wbSocket: WebSocketService<MessageRequest, MessageResponse>;
    private infoList: Array<MessageRequest> = [];
    toast = useToast();
    private tableRequestItem: any = [];
    private modelValue: WorksTableItem = new WorksTableItem();
    private infoData: WorksTableItem = new WorksTableItem();
    private forceToDown = false;
    private nameSelect: any = {};

    public nameSelectHandle(i: any): void {
        this.nameSelect[i] = !this.nameSelect[i];
        Object.keys(this.nameSelect).forEach((key) => {
            if (parseInt(key) !== i) {
                this.nameSelect[key] = false;
            }
        });
    }

    public created(): void {
        this.tableRequestItem.lockItem = this.getLockItem();
    }

    //UserAPI
    get getUserApi(): any {
        return UserAPI;
    }

    get getBusinessApi() {
        return BusinessApi;
    }

    get getInfluenceApi() {
        return InfluenceApi;
    }

    get windowHeight() {
        return this.$windowHeight;
    }

    get loading() {
        if (this.loading1 || this.loading2) return true;
        return this.loading1 && this.loading2;
    }

    public modRoleAuth(rolePath: string, sendUserCode: string): boolean {
        if (!this.$tools.isEmpty(this.infoData.completeName)) return false;
        if (!this.$tools.isEmpty(this.infoData.completeTime)) return false;
        const userCode = this.authUser.userCode;
        //发起人修改发起详情
        if (userCode === sendUserCode && rolePath === "/aftersale/ticket/works/modWorkDescribeLaunch") return true;
        //责任人修改权限
        if (userCode === this.infoData.ownerProject) return true;
        if (userCode === this.infoData.ownerMain) return true;
        if (userCode === this.infoData.ownerInfo) return true;
        if (userCode === this.infoData.ownerIntegrated) return true;
        return this.Auth(rolePath);
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            userCode: {
                type: "eq",
                name: "u.user_code",
                title: "人员选择",
                parent: null,
                value: null,
            },
            businessId: {
                type: "router",
                name: "business_id",
                title: "故障类型",
                parent: null,
                value: null,
            },
            influenceId: {
                type: "router",
                name: "influence_id",
                title: "影响范围",
                parent: null,
                value: null,
            },
        };
    }

    private setActiveTab(activeTab: string) {
        this.activeTab = activeTab;
        this.forceToDown = true;
        this.scrollToBottom();
    }

    public openModal() {
        this.tableRequestItem.lockItem.businessId.parent = null;
        this.tableRequestItem.lockItem.influenceId.parent = null;
        (this.$refs.viewModal as any).openModal();
        this.$nextTick(() => {
            this.setActiveTab("COMMON1");
        });
    }

    public chatSubmit(option: any) {
        this.message = "";
        this.send(option);
        (this.$refs.chatInput as any).focus();
    }

    public send(option: any) {
        if (!option.infoType || this.$tools.isEmpty(option.infoType)) option.infoType = "string";
        if (!option.infoData || this.$tools.isEmpty(option.infoData)) return;

        const info: MessageRequest = <MessageRequest>{
            style: this.workStyle,
            work: this.infoData.workCode,
            type: 2,
            send: this.authUser.userName,
            receive: "",
            id: uuidv4(),
        };
        Object.assign(info, option);
        this.loading1 = true;
        this.forceToDown = true;
        this.wbSocket.send(info);
    }

    public async modelOpened() {
        this.infoData = this.modelValue;
        //如果传的是workCode，则更新工单信息
        if (!this.$tools.isEmpty(this.workCode)) await this.getWorkData(this.workCode);
        //连接websocket
        await this.openWebSocket();
    }

    public modelClosed() {
        this.closeWebSocket();
    }

    public unmounted(): void {
        this.closeWebSocket();
    }

    public destroyed() {
        this.closeWebSocket();
    }

    public closeWebSocket() {
        if (!this.wbSocket) return;
        if (!this.wbSocket.options) return;
        if (!this.wbSocket.options.isDestroy) this.wbSocket.destroy();
    }

    //滚动条位置
    public scrollPosition() {
        const chatMessagesListElement = this.$refs.chatMessagesList as HTMLElement;
        if (this.$tools.isEmpty(chatMessagesListElement)) return false;
        const scrollTop = chatMessagesListElement.scrollTop;
        const scrollHeight = chatMessagesListElement.scrollHeight;
        const clientHeight = chatMessagesListElement.clientHeight;
        // console.log('scrollTop=' + scrollTop);
        // console.log('scrollHeight=' + scrollHeight);
        // console.log('clientHeight=' + clientHeight);
        // return scrollTop + clientHeight === scrollHeight;
        return scrollHeight - (scrollTop + clientHeight) < 1;
    }

    //聊天框自动下拉
    public scrollToBottom() {
        if (this.forceToDown || this.scrollPosition()) {
            this.$nextTick(() => {
                const chatMessagesListElement = this.$refs.chatMessagesList as HTMLElement;
                chatMessagesListElement.scrollTop = chatMessagesListElement.scrollHeight;
            });
            this.forceToDown = false;
        }
    }

    //连接websocket
    public async openWebSocket() {
        this.loading1 = true;
        this.wbSocket = new WebSocketService<MessageRequest, MessageResponse>({
            url: WebSocketMessageUrl,
            style: this.workStyle,
            //work: this.infoData.workCode,
            work: "test",
            openCb: (e) => {
                // 连接成功回调
                console.log("连接成功：", e);
                this.infoList = [];
            },
            closeCb: (e) => {
                // 关闭回调
                console.log("连接关闭：", e);
                this.loading1 = false;
            },
            messageCb: (info: MessageResponse) => {
                this.loading1 = false;
                // 消息回调
                if (info.code == 200) {
                    const dataList: Array<MessageRequest> = info.data;
                    for (const item of dataList) this.infoList.push(item);
                } else {
                    this.toast.error(info.msg);
                    return;
                }
            },
            errorCb: (e) => {
                // 错误回调
                console.log("连接错误：", e);
                this.loading1 = false;
            },
        });
    }

    //获取工单信息
    public async getWorkData(workCode: string): Promise<void> {
        this.loading2 = true;
        await WorksApi.workData({ workCode })
            .then(async (res: any) => {
                await this.$emit("update:modelValue", res.data);
            })
            .finally(() => {
                this.loading2 = false;
            });
    }

    //userCode搜索字段
    private userCodeLevelSearchField: any = [
        { field: "o.org_name", name: "组织名称" },
        { field: "o.org_remark", name: "组织说明" },
        { field: "u.user_name", name: "用户名" },
        { field: "u.real_name", name: "姓名" },
    ];
    //userCode层级选择器打开
    private userCodeLevelFieldArray: any = [];
    public userCodeLevelModalOpen(fieldArray: any) {
        if (fieldArray[0] != this.userCodeLevelFieldArray[0]) {
            this.userCodeLevelFieldArray = fieldArray;
            this.tableRequestItem.lockItem.userCode.value = null;
            this.tableRequestItem.lockItem.userCode.parent = null;
            this.tableRequestItem.lockItem.userCode.title = "人员选择";
        }

        let isAuthOk = false;
        if (this.userCodeLevelFieldArray[0] == "ownerProject" && this.modRoleAuth("/aftersale/ticket/works/modOwnerProject", this.infoData.userCode)) {
            isAuthOk = true;
        } else if (this.userCodeLevelFieldArray[0] == "ownerMain" && this.modRoleAuth("/aftersale/ticket/works/modOwnerMain", this.infoData.userCode)) {
            isAuthOk = true;
        } else if (this.userCodeLevelFieldArray[0] == "ownerInfo" && this.modRoleAuth("/aftersale/ticket/works/modOwnerInfo", this.infoData.userCode)) {
            isAuthOk = true;
        } else if (this.userCodeLevelFieldArray[0] == "ownerIntegrated" && this.modRoleAuth("/aftersale/ticket/works/modOwnerIntegrated", this.infoData.userCode)) {
            isAuthOk = true;
        }
        if (!isAuthOk) return;

        if (this.userCodeLevelFieldArray[0] == "ownerProject") {
            this.tableRequestItem.lockItem.userCode.value = this.infoData.ownerProject;
        } else if (this.userCodeLevelFieldArray[0] == "ownerMain") {
            this.tableRequestItem.lockItem.userCode.value = this.infoData.ownerMain;
        } else if (this.userCodeLevelFieldArray[0] == "ownerInfo") {
            this.tableRequestItem.lockItem.userCode.value = this.infoData.ownerInfo;
        } else if (this.userCodeLevelFieldArray[0] == "ownerIntegrated") {
            this.tableRequestItem.lockItem.userCode.value = this.infoData.ownerIntegrated;
        }
        if (!this.tableRequestItem.lockItem.userCode.value) this.tableRequestItem.lockItem.userCode.parent = this.authUser.orgId;

        if (!this.tableRequestItem.lockItem.userCode.parent) {
            this.loading2 = true;
            UserApi.parentId({
                userCode: this.tableRequestItem.lockItem.userCode.value,
            })
                .then((res: any) => {
                    this.tableRequestItem.lockItem.userCode.parent = res.data.orgId;
                    (this.$refs.userCodeLevel as any).openModal();
                })
                .finally(() => {
                    this.loading2 = false;
                });
        } else {
            (this.$refs.userCodeLevel as any).openModal();
        }
    }
    //userCode层级选择器提交
    public async userCodeLevelFunction(item: any): Promise<void> {
        let isAuthOk = false;
        if (this.userCodeLevelFieldArray[0] == "ownerProject" && this.modRoleAuth("/aftersale/ticket/works/modOwnerProject", this.infoData.userCode)) {
            isAuthOk = true;
        } else if (this.userCodeLevelFieldArray[0] == "ownerMain" && this.modRoleAuth("/aftersale/ticket/works/modOwnerMain", this.infoData.userCode)) {
            isAuthOk = true;
        } else if (this.userCodeLevelFieldArray[0] == "ownerInfo" && this.modRoleAuth("/aftersale/ticket/works/modOwnerInfo", this.infoData.userCode)) {
            isAuthOk = true;
        } else if (this.userCodeLevelFieldArray[0] == "ownerIntegrated" && this.modRoleAuth("/aftersale/ticket/works/modOwnerIntegrated", this.infoData.userCode)) {
            isAuthOk = true;
        }
        if (!isAuthOk) return;
        if (this.$tools.isEmpty(item.value)) {
            this.toast.error("请选择" + this.userCodeLevelFieldArray[1] + "！");
            return Promise.reject();
        }
        let api: any;
        if (this.userCodeLevelFieldArray[0] == "ownerProject") {
            api = (opt: any) => WorksApi.modOwnerProject(opt);
        } else if (this.userCodeLevelFieldArray[0] == "ownerMain") {
            api = (opt: any) => WorksApi.modOwnerMain(opt);
        } else if (this.userCodeLevelFieldArray[0] == "ownerInfo") {
            api = (opt: any) => WorksApi.modOwnerInfo(opt);
        } else if (this.userCodeLevelFieldArray[0] == "ownerIntegrated") {
            api = (opt: any) => WorksApi.modOwnerIntegrated(opt);
        } else {
            this.toast.error("未知api！");
            return Promise.reject();
        }
        await api({
            workCode: this.infoData.workCode,
            userCode: item.value,
        }).then((res: any) => {
            this.toast.success(res.msg);
            if (this.userCodeLevelFieldArray[0] == "ownerProject") {
                this.infoData.ownerProject = item.value;
                this.infoData.ownerProjectName = res.data.ownerProjectName;
            } else if (this.userCodeLevelFieldArray[0] == "ownerMain") {
                this.infoData.ownerMain = item.value;
                this.infoData.ownerMainName = res.data.ownerMainName;
            } else if (this.userCodeLevelFieldArray[0] == "ownerInfo") {
                this.infoData.ownerInfo = item.value;
                this.infoData.ownerInfoName = res.data.ownerInfoName;
            } else if (this.userCodeLevelFieldArray[0] == "ownerIntegrated") {
                this.infoData.ownerIntegrated = item.value;
                this.infoData.ownerIntegratedName = res.data.ownerIntegratedName;
            }
            this.infoData.lastReplyTime = res.data.lastReplyTime;
            this.infoData.lastReplyName = res.data.lastReplyName;

            this.tableRequestItem.lockItem.userCode.value = item.value;
            this.tableRequestItem.lockItem.userCode.title = item.title;
            this.tableRequestItem.lockItem.userCode.parent = item.parent;
        });
        return Promise.resolve();
    }

    //influenceId搜索字段
    private influenceIdLevelSearchField: any = [
        { field: "influence_name", name: "名称" },
        { field: "influence_remark", name: "说明" },
    ];
    //influenceId层级选择器打开
    public influenceIdLevelModalOpen() {
        if (!this.modRoleAuth("/aftersale/ticket/works/modInfluenceId", this.infoData.userCode)) return;
        if (!this.tableRequestItem.lockItem.influenceId.parent) {
            this.loading2 = true;
            InfluenceApi.parentId({
                influenceId: this.infoData.influenceId,
            })
                .then((res: any) => {
                    this.tableRequestItem.lockItem.influenceId.value = this.infoData.influenceId;
                    this.tableRequestItem.lockItem.influenceId.parent = res.data.influenceParentId;
                    (this.$refs.influenceIdLevel as any).openModal();
                })
                .finally(() => {
                    this.loading2 = false;
                });
        } else {
            (this.$refs.influenceIdLevel as any).openModal();
        }
    }
    //influenceId层级选择器提交
    public async influenceIdLevelFunction(item: any): Promise<void> {
        if (!this.modRoleAuth("/aftersale/ticket/works/modInfluenceId", this.infoData.userCode)) return Promise.reject();
        if (this.$tools.isEmpty(item.value)) {
            this.toast.error("请选择影响范围！");
            return Promise.reject();
        }
        await WorksApi.modInfluenceId({
            workCode: this.infoData.workCode,
            influenceId: item.value,
        }).then((res: any) => {
            this.toast.success(res.msg);
            this.infoData.influenceId = item.value;
            this.infoData.influenceIdName = res.data.influenceIdName;
            this.infoData.workStar = res.data.workStar;

            this.infoData.lastReplyTime = res.data.lastReplyTime;
            this.infoData.lastReplyName = res.data.lastReplyName;

            this.tableRequestItem.lockItem.businessId.value = item.value;
            this.tableRequestItem.lockItem.businessId.title = item.title;
            this.tableRequestItem.lockItem.businessId.parent = item.parent;
        });
        return Promise.resolve();
    }

    //businessId搜索字段
    private businessIdLevelSearchField: any = [
        { field: "business_name", name: "名称" },
        { field: "business_remark", name: "说明" },
    ];
    //businessId层级选择器打开
    public businessIdLevelModalOpen() {
        if (!this.modRoleAuth("/aftersale/ticket/works/modBusinessId", this.infoData.userCode)) return;
        if (!this.tableRequestItem.lockItem.businessId.parent) {
            this.loading2 = true;
            BusinessApi.parentId({
                businessId: this.infoData.businessId,
            })
                .then((res: any) => {
                    this.tableRequestItem.lockItem.businessId.value = this.infoData.businessId;
                    this.tableRequestItem.lockItem.businessId.parent = res.data.businessParentId;
                    (this.$refs.businessIdLevel as any).openModal();
                })
                .finally(() => {
                    this.loading2 = false;
                });
        } else {
            (this.$refs.businessIdLevel as any).openModal();
        }
    }
    //businessId层级选择器提交
    public async businessIdLevelFunction(item: any): Promise<void> {
        if (!this.modRoleAuth("/aftersale/ticket/works/modBusinessId", this.infoData.userCode)) return Promise.reject();
        if (this.$tools.isEmpty(item.value)) {
            this.toast.error("请选择业务类型！");
            return Promise.reject();
        }
        await WorksApi.modBusinessId({
            workCode: this.infoData.workCode,
            businessId: item.value,
        }).then((res: any) => {
            this.toast.success(res.msg);
            this.infoData.businessId = item.value;
            this.infoData.businessIdName = res.data.businessIdName;
            this.infoData.workStar = res.data.workStar;

            this.infoData.lastReplyTime = res.data.lastReplyTime;
            this.infoData.lastReplyName = res.data.lastReplyName;

            this.tableRequestItem.lockItem.businessId.value = item.value;
            this.tableRequestItem.lockItem.businessId.title = item.title;
            this.tableRequestItem.lockItem.businessId.parent = item.parent;
        });
        return Promise.resolve();
    }

    //修改workDescribeLaunch
    public workDescribeLaunchModalOpen() {
        if (!this.modRoleAuth("/aftersale/ticket/works/modWorkDescribeLaunch", this.infoData.userCode)) return;
        (this.$refs.workDescribeLaunchModal as any).openModal();
    }
    public async workDescribeLaunchFunction(value: string): Promise<void> {
        if (!this.modRoleAuth("/aftersale/ticket/works/modWorkDescribeLaunch", this.infoData.userCode)) return Promise.reject();
        if (this.$tools.isEmpty(value)) {
            this.toast.error("请输入发起详情！");
            return Promise.reject();
        }
        await WorksApi.modWorkDescribeLaunch({
            workCode: this.infoData.workCode,
            workDescribeLaunch: value,
        }).then((res: any) => {
            this.toast.success(res.msg);
            this.infoData.workDescribeLaunch = value;

            this.infoData.lastReplyTime = res.data.lastReplyTime;
            this.infoData.lastReplyName = res.data.lastReplyName;
        });
        return Promise.resolve();
    }

    //修改workDescribeLaunch
    public workDescribeProcessModalOpen() {
        if (!this.modRoleAuth("/aftersale/ticket/works/modWorkDescribeProcess", this.infoData.userCode)) return;
        (this.$refs.workDescribeProcessModal as any).openModal();
    }
    public async workDescribeProcessFunction(value: string): Promise<void> {
        if (!this.modRoleAuth("/aftersale/ticket/works/modWorkDescribeProcess", this.infoData.userCode)) return Promise.reject();
        if (this.$tools.isEmpty(value)) {
            this.toast.error("请输入处理详情！");
            return Promise.reject();
        }
        await WorksApi.modWorkDescribeProcess({
            workCode: this.infoData.workCode,
            workDescribeProcess: value,
        }).then((res: any) => {
            this.toast.success(res.msg);
            this.infoData.workDescribeProcess = value;

            this.infoData.lastReplyTime = res.data.lastReplyTime;
            this.infoData.lastReplyName = res.data.lastReplyName;
        });
        return Promise.resolve();
    }
}
