export default class WorksTableItem {
    workId = 0;
    workStatus = 0;
    workStatusName = "";
    workCode = "";
    userCode = "";
    projectId = 0;
    projectIdName = "";
    processInstanceId = "";
    businessId = 0;
    businessIdName = "";
    influenceId = 0;
    influenceIdName = "";
    workStar = 0;
    workSubject = "";
    workDescribeLaunch = "";
    workDescribeProcess = "";
    workSchedule = 0;
    lastReplyTime = "";
    lastReplyName = "";
    completeTime = "";
    completeName = "";
    createTime = "";
    createName = "";
    ownerProject = "";
    ownerProjectName = "";
    ownerMain = "";
    ownerMainName = "";
    ownerInfo = "";
    ownerInfoName = "";
    ownerIntegrated = "";
    ownerIntegratedName = "";

    sendUserCode = "";
    sendUserName = "";
    sendRealName = "";
    sendUserIcon = "";
    sendOrgCode = "";
    sendOrgName = "";
    sendDeptId = 0;
    sendDeptIdName = "";

    fromOrgCode = "";
    fromOrgName = "";
    fromOrgTypeId = 0;
    fromOrgTypeIdName = "";

    activityName = "";
    processDuration = "";
    taskDuration = "";

    isProcess = false;
}
