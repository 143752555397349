import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/aftersale/ticket/works",
    // 获取工单信息
    workData(query: any) {
        return requests.post(this.modulesUrl + "/workData", query);
    },
    // 修改影响范围
    modInfluenceId(query: any) {
        return requests.post(this.modulesUrl + "/modInfluenceId", query);
    },
    // 修改业务类型
    modBusinessId(query: any) {
        return requests.post(this.modulesUrl + "/modBusinessId", query);
    },
    // 修改发起详情
    modWorkDescribeLaunch(query: any) {
        return requests.post(this.modulesUrl + "/modWorkDescribeLaunch", query);
    },
    // 修改处理详情
    modWorkDescribeProcess(query: any) {
        return requests.post(this.modulesUrl + "/modWorkDescribeProcess", query);
    },
    // 修改项目责任人
    modOwnerProject(query: any) {
        return requests.post(this.modulesUrl + "/modOwnerProject", query);
    },
    // 修改运维责任人
    modOwnerMain(query: any) {
        return requests.post(this.modulesUrl + "/modOwnerMain", query);
    },
    // 修改信息责任人
    modOwnerInfo(query: any) {
        return requests.post(this.modulesUrl + "/modOwnerInfo", query);
    },
    // 修改集成责任人
    modOwnerIntegrated(query: any) {
        return requests.post(this.modulesUrl + "/modOwnerIntegrated", query);
    },
    //修改进度
    modSchedule(query: any) {
        return requests.post(this.modulesUrl + "/modSchedule", query);
    },
};
