import { Options } from "vue-class-component";
import WorksTableItem from "@/entity/aftersale/ticket/WorksTableItem";
import WorksStatusApi from "@/api/aftersale/ticket/status";
import BaseFunction from "@/mixins/BaseFunction";
import WorksApi from "@/api/aftersale/ticket/works";

@Options({
    name: "app-work-schedule",
    props: {
        modelValue: Object,
        //传workCode会从数据库更新工单信息，不传则使用modelValue
        workCode: {
            type: String,
            default: null,
        },
    },
    emits: ["update:modelValue"],
})
export default class WorkSchedule extends BaseFunction {
    private modelValue: WorksTableItem = new WorksTableItem();
    private infoData: WorksTableItem = new WorksTableItem();
    private scheduleRemark = "";
    private statusList: any = {};
    private workScheduleDisabled = false;

    public openModal() {
        (this.$refs.scheduleModal as any).openModal();
    }

    public async modelOpened() {
        this.scheduleRemark = "";
        this.infoData = Object.assign({}, this.modelValue);
        this.workScheduleDisabled = false;
    }

    public workStatusChange(status: number) {
        //状态不存在退出
        if (!this.statusList[status]) return;
        //修改进度
        this.infoData.workSchedule = this.statusList[status].schedule.val;
        //修改进度说明
        this.scheduleRemark = this.statusList[status].schedule.remark;
        //状态选择完成时，禁用进度输入框
        this.workScheduleDisabled = this.statusList[status].schedule.val === 100;
    }

    // :disabled="workScheduleDisabled" @input.native="workScheduleChange($event)"
    public workScheduleChange(event: any) {
        //限定进度只能是0-100
        let newVal = parseInt(event.target.value);
        if (this.$tools.isEmpty(newVal)) newVal = 0;
        if (newVal < 0) newVal = 0;
        if (newVal > 100) newVal = 100;
        event.target.value = newVal;
        this.infoData.workSchedule = newVal;

        //进度达到100时，修改状态
        if (newVal === 100) {
            for (const key in this.statusList) {
                const item = this.statusList[key];
                if (item.schedule.val === 100) {
                    this.infoData.workStatus = item.value;
                    break;
                }
            }
        }
    }

    public async scheduleSubmit(): Promise<void> {
        const workStatus = this.infoData.workStatus;
        const workSchedule = this.infoData.workSchedule;
        const scheduleRemark = this.scheduleRemark;
        if (this.$tools.isEmpty(workStatus) && this.statusList[workStatus]) {
            this.toast.error("请选择工单状态");
            return Promise.reject();
        }
        if (this.$tools.isEmpty(workSchedule)) {
            this.toast.error("请输入完成进度百分比");
            return Promise.reject();
        }
        if (this.$tools.isEmpty(scheduleRemark)) {
            this.toast.error("请输入进度说明");
            return Promise.reject();
        }
        const workStatusName = this.statusList[workStatus].name;

        await WorksApi.modSchedule({
            workCode: this.infoData.workCode,
            workStatus,
            workSchedule,
            scheduleRemark,
        }).then((res: any) => {
            this.toast.success(res.msg);
            this.infoData.workStatusName = workStatusName;
            this.modelValue.workStatusName = workStatusName;
            this.modelValue.workStatus = workStatus;
            this.modelValue.workSchedule = workSchedule;

            this.modelValue.lastReplyTime = res.data.lastReplyTime;
            this.modelValue.lastReplyName = res.data.lastReplyName;

            if (!this.$tools.isEmpty(res.data.completeTime)) this.modelValue.completeTime = res.data.completeTime;
            if (!this.$tools.isEmpty(res.data.lastReplyName)) this.modelValue.lastReplyName = res.data.lastReplyName;
        });
        return Promise.resolve();
    }

    public requestCallBack(list: any) {
        this.statusList = {};
        for (let i = 0; i < list.length; i++) this.statusList[list[i].value] = list[i];
        if (!this.statusList[1] && list[0]) this.statusList[1] = list[0];
    }

    get worksStatusIdSelectApi() {
        return {
            api: (query: any) => WorksStatusApi.idSelect(query),
            status: "status_status",
            name: "status_name",
            id: "status_id",
            cid: this.infoData.workStatus,
        };
    }
}
