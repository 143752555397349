import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_app_select_from_db = _resolveComponent("app-select-from-db")
  const _component_app_input = _resolveComponent("app-input")
  const _component_app_textarea = _resolveComponent("app-textarea")
  const _component_app_modal = _resolveComponent("app-modal")

  return (_openBlock(), _createBlock(_component_app_modal, {
    ref: "scheduleModal",
    title: "提交进度",
    submitButtonName: "提交",
    submitFunction: _ctx.scheduleSubmit,
    "onUpdate:modelOpened": _ctx.modelOpened
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_select_from_db, {
        label: "工单状态",
        modelValue: _ctx.infoData.workStatus,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.infoData.workStatus) = $event)),
        searchApi: _ctx.worksStatusIdSelectApi,
        requestCallBack: _ctx.requestCallBack,
        "onUpdate:changeValue": _ctx.workStatusChange
      }, null, 8, ["modelValue", "searchApi", "requestCallBack", "onUpdate:changeValue"]),
      _createVNode(_component_app_input, {
        label: "完成进度",
        unit: "%",
        modelValue: _ctx.infoData.workSchedule,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.infoData.workSchedule) = $event)),
        readonly: ""
      }, null, 8, ["modelValue"]),
      _createVNode(_component_app_textarea, {
        label: "进度说明",
        modelValue: _ctx.scheduleRemark,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.scheduleRemark) = $event))
      }, null, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["submitFunction", "onUpdate:modelOpened"]))
}